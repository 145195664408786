<template>
  <div>
    <!-- <el-card class="box-card">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-card class="box-item box-pulusred">
            <span>用户量(1820)</span>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card class="box-item box-pulusrgreen">
            <span>文章总量(80)</span>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card class="box-item box-pulusblue">
            <span>资源数量(92)</span>
          </el-card>
        </el-col>
      </el-row>
    </el-card> -->

    <el-row class="e-row" :gutter="24">
      <el-col :span="6">
        <el-card class="box-item1">
          <template #header>
            <div class="card-header">
              <span class="card-title">快速办公</span>
            </div>
          </template>
          <div class="list-btn">
            <el-button type="primary">新增文章</el-button>
            <el-button type="success">新增资源</el-button>
            <el-button type="info">权限管理</el-button>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-item1">
          <template #header>
            <div class="card-header">
              <span class="card-title">最新通知(5)</span>

              <span class="card-title">更多</span>
            </div>
          </template>
          <div class="list-btn">
            <ul>
              <li>
                暂无通知
              </li>
              <!-- <li>
                <a href="#">
                  <span>这是一则通知</span>
                  <span>2023-8-16</span>
                </a>
              </li> -->
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-item1">
          <el-calendar  class="hz-cale" :range="[new Date(2019, 2, 4), new Date(2019, 2, 24)]" />
        </el-card>
      </el-col>

    </el-row>
  </div>
</template>

<script>
   
</script>
<style  >
.box-card {
  margin-bottom: 20px;
}

.card-header{
  display: flex;
  justify-content: space-between;
}

.box-item {
  height: 110px;
  color: white;
}


.list-btn ul,li{
  margin: 0; 
  padding: 0;
  list-style: none;
  list-style-type: none;
}

.list-btn li{
  height:40px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.list-btn li>a{
  text-decoration: none;
  color: black;
  width: 100%;
}

.list-btn  a{
  display: flex;
  justify-content: space-between;
}

.list-btn  li:hover{
   background: #ccc;
   color: #fff;
}

.list-btn  li:hover>a{
  color: #fff;
}

.e-row {
  margin-bottom: 15px;
}

.box-pulusred {
  background: #FF6188;
}


.box-pulusblue {
  background: rgb(11, 174, 255);
}


.box-pulusrgreen {
  background: #6C74FF;
}

.box-item1 {
  height: 300px;
}

.card-title {
  font-weight: bold;
}


.el-calendar-table .el-calendar-day {
  height: 40px;
}
</style>